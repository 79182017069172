import {
    FETCH_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION,
    REMOVE_SUBSCRIPTION,
} from '../contants';

const INIT_STATE = {
    subscriptionData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionData: [action.payload, ...state.subscriptionData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_SUBSCRIPTION:
            return {
                ...state,
                subscriptionData: state.subscriptionData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_SUBSCRIPTION:
            return {
                ...state,
                subscriptionData: state.subscriptionData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
