import {
    FETCH_PRODUCT_SUCCESS,
    UPDATE_PRODUCT,
    REMOVE_PRODUCT,
} from '../contants';

const INIT_STATE = {
    productData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                productData: [action.payload, ...state.productData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                productData: state.productData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productData: state.productData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
