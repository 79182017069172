import {
    FETCH_MERCHANT_PAYMENT_SUCCESS,
    UPDATE_MERCHANT_PAYMENT,
    REMOVE_MERCHANT_PAYMENT,
} from '../contants';

const INIT_STATE = {
    merchantPaymentData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_MERCHANT_PAYMENT_SUCCESS:
            return {
                ...state,
                merchantPaymentData: [action.payload, ...state.merchantPaymentData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_MERCHANT_PAYMENT:
            return {
                ...state,
                merchantPaymentData: state.merchantPaymentData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_MERCHANT_PAYMENT:
            return {
                ...state,
                merchantPaymentData: state.merchantPaymentData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
