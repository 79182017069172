import {
    FETCH_REQUIREMENT_SUCCESS,
    UPDATE_REQUIREMENT,
    REMOVE_REQUIREMENT,
} from '../contants';

const INIT_STATE = {
    requirementData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_REQUIREMENT_SUCCESS:
            return {
                ...state,
                requirementData: [action.payload, ...state.requirementData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_REQUIREMENT:
            return {
                ...state,
                requirementData: state.requirementData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_REQUIREMENT:
            return {
                ...state,
                requirementData: state.requirementData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
