import {
    FETCH_ORDERSBYMID_SUCCESS,
    UPDATE_ORDERSBYMID,
    REMOVE_ORDERSBYMID,
} from '../contants';

const INIT_STATE = {
    ordersByMidData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ORDERSBYMID_SUCCESS:
            return {
                ...state,
                ordersByMidData: [action.payload, ...state.ordersByMidData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_ORDERSBYMID:
            return {
                ...state,
                ordersByMidData: state.ordersByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_ORDERSBYMID:
            return {
                ...state,
                ordersByMidData: state.ordersByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
