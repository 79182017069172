import {
    FETCH_PRODUCTBYMID_SUCCESS,
    UPDATE_PRODUCTBYMID,
    REMOVE_PRODUCTBYMID,
    REMOVE_ALLPRODUCTBYMID,
} from '../contants';

const INIT_STATE = {
    productByMidData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_PRODUCTBYMID_SUCCESS:
            return {
                ...state,
                productByMidData: [action.payload, ...state.productByMidData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_PRODUCTBYMID:
            return {
                ...state,
                productByMidData: state.productByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_PRODUCTBYMID:
            return {
                ...state,
                productByMidData: state.productByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
            case REMOVE_ALLPRODUCTBYMID:
                return {
                    productByMidData: [],
                    isRefresh: false,
                };
        default:
            return { ...state };
    }
};