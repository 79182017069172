import {
    FETCH_PROGRAM_SUCCESS,
    UPDATE_PROGRAM,
    REMOVE_PROGRAM,
} from '../contants';

const INIT_STATE = {
    programData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_PROGRAM_SUCCESS:
            return {
                ...state,
                programData: [action.payload, ...state.programData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_PROGRAM:
            return {
                ...state,
                programData: state.programData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_PROGRAM:
            return {
                ...state,
                programData: state.programData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
