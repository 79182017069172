import {
    FETCH_REFERAL_SUCCESS,
    UPDATE_REFERAL,
    REMOVE_REFERAL,
} from '../contants';

const INIT_STATE = {
    referalData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_REFERAL_SUCCESS:
            return {
                ...state,
                referalData: [action.payload, ...state.referalData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_REFERAL:
            return {
                ...state,
                referalData: state.referalData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_REFERAL:
            return {
                ...state,
                referalData: state.referalData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
