import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import product from './product/reducer';
import user from './user/reducer';
import program from './program/reducer';
import referal from './referal/reducer';
import category from './category/reducer';
import productType from './productType/reducer';
import variant from './variant/reducer';
import coupon from './coupon/reducer';
import subscriptionType from './subscriptionType/reducer';
import adds from './adds/reducer';
import news from './news/reducer';
import website from './website/reducer';
import ordersByMid from './ordersByMid/reducer';
import requirement from './requirement/reducer';
import merchant from './merchant/reducer';
import earlybirdreg from './earlybirdreg/reducer';
import orderTransaction from './orderTransaction/reducer';
import orderCancleTransaction from './orderCancleTransaction/reducer';
import orderReturnTransaction from './orderReturnTransaction/reducer';
import merchantTransaction from './merchantTransaction/reducer';
import merchantPayment from './merchantPayment/reducer';
import cartsByMid from './cartsByMid/reducer';
import subscription from './subscription/reducer';
import productByMid from './productByMid/reducer';
import banner from './banner/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  product,
  user,
  program,
  referal,
  category,
  productType,
  variant,
  coupon,
  subscriptionType,
  adds,
  news,
  website,
  ordersByMid,
  requirement,
  merchant,
  earlybirdreg,
  orderTransaction,
  orderCancleTransaction,
  orderReturnTransaction,
  merchantTransaction,
  merchantPayment,
  cartsByMid,
  subscription,
  productByMid,
  banner,
});

export default reducers;
