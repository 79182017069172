import {
    FETCH_CARTSBYMID_SUCCESS,
    UPDATE_CARTSBYMID,
    REMOVE_CARTSBYMID,
} from '../contants';

const INIT_STATE = {
    cartsByMidData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CARTSBYMID_SUCCESS:
            return {
                ...state,
                cartsByMidData: [action.payload, ...state.cartsByMidData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_CARTSBYMID:
            return {
                ...state,
                cartsByMidData: state.cartsByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_CARTSBYMID:
            return {
                ...state,
                cartsByMidData: state.cartsByMidData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
