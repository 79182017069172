import {
    FETCH_SUBSCRIPTIONTYPE_SUCCESS,
    UPDATE_SUBSCRIPTIONTYPE,
    REMOVE_SUBSCRIPTIONTYPE,
} from '../contants';

const INIT_STATE = {
    subscriptionTypeData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTIONTYPE_SUCCESS:
            return {
                ...state,
                subscriptionTypeData: [action.payload, ...state.subscriptionTypeData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_SUBSCRIPTIONTYPE:
            return {
                ...state,
                subscriptionTypeData: state.subscriptionTypeData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_SUBSCRIPTIONTYPE:
            return {
                ...state,
                subscriptionTypeData: state.subscriptionTypeData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
