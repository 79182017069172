import {
    FETCH_BANNER_SUCCESS,
    UPDATE_BANNER,
    REMOVE_BANNER,
} from '../contants';

const INIT_STATE = {
    bannerData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_BANNER_SUCCESS:
            return {
                ...state,
                bannerData: [action.payload, ...state.bannerData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_BANNER:
            return {
                ...state,
                bannerData: state.bannerData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_BANNER:
            return {
                ...state,
                bannerData: state.bannerData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
