import {
    FETCH_ORDERCANCLE_TRANSACTION_SUCCESS,
    UPDATE_ORDERCANCLE_TRANSACTION,
    REMOVE_ORDERCANCLE_TRANSACTION,
} from '../contants';

const INIT_STATE = {
    orderCancelTransactionData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ORDERCANCLE_TRANSACTION_SUCCESS:
            return {
                ...state,
                orderCancelTransactionData: [action.payload, ...state.orderCancelTransactionData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_ORDERCANCLE_TRANSACTION:
            return {
                ...state,
                orderCancelTransactionData: state.orderCancelTransactionData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_ORDERCANCLE_TRANSACTION:
            return {
                ...state,
                orderCancelTransactionData: state.orderCancelTransactionData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
