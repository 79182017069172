import {
    FETCH_ADDS_SUCCESS,
    UPDATE_ADDS,
    REMOVE_ADDS,
} from '../contants';

const INIT_STATE = {
    addsData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ADDS_SUCCESS:
            return {
                ...state,
                addsData: [action.payload, ...state.addsData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_ADDS:
            return {
                ...state,
                addsData: state.addsData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_ADDS:
            return {
                ...state,
                addsData: state.addsData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
