import {
    FETCH_NEWS_SUCCESS,
    UPDATE_NEWS,
    REMOVE_NEWS,
} from '../contants';

const INIT_STATE = {
    newsData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                newsData: [action.payload, ...state.newsData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_NEWS:
            return {
                ...state,
                newsData: state.newsData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_NEWS:
            return {
                ...state,
                newsData: state.newsData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
