import {
    FETCH_EARLYBIRDREG_SUCCESS,
    UPDATE_EARLYBIRDREG,
    REMOVE_EARLYBIRDREG,
} from '../contants';

const INIT_STATE = {
    earlybirdregData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_EARLYBIRDREG_SUCCESS:
            return {
                ...state,
                earlybirdregData: [action.payload, ...state.earlybirdregData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_EARLYBIRDREG:
            return {
                ...state,
                earlybirdregData: state.earlybirdregData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_EARLYBIRDREG:
            return {
                ...state,
                earlybirdregData: state.earlybirdregData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
