/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/*product*/
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

/*user*/
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

/*program*/
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const REMOVE_PROGRAM = 'REMOVE_PROGRAM';

/*referal*/
export const FETCH_REFERAL_SUCCESS = 'FETCH_REFERAL_SUCCESS';
export const UPDATE_REFERAL = 'UPDATE_REFERAL';
export const REMOVE_REFERAL = 'REMOVE_REFERAL';

/*category*/
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

/*productType*/
export const FETCH_PRODUCTTYPE_SUCCESS = 'FETCH_PRODUCTTYPE_SUCCESS';
export const UPDATE_PRODUCTTYPE = 'UPDATE_PRODUCTTYPE';
export const REMOVE_PRODUCTTYPE = 'REMOVE_PRODUCTTYPE';

/*variant*/
export const FETCH_VARIANT_SUCCESS = 'FETCH_VARIANT_SUCCESS';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const REMOVE_VARIANT = 'REMOVE_VARIANT';

/*coupon*/
export const FETCH_COUPON_SUCCESS = 'FETCH_COUPON_SUCCESS';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';

/*subscriptionType*/
export const FETCH_SUBSCRIPTIONTYPE_SUCCESS = 'FETCH_SUBSCRIPTIONTYPE_SUCCESS';
export const UPDATE_SUBSCRIPTIONTYPE = 'UPDATE_SUBSCRIPTIONTYPE';
export const REMOVE_SUBSCRIPTIONTYPE = 'REMOVE_SUBSCRIPTIONTYPE';

/*adds*/
export const FETCH_ADDS_SUCCESS = 'FETCH_ADDS_SUCCESS';
export const UPDATE_ADDS = 'UPDATE_ADDS';
export const REMOVE_ADDS = 'REMOVE_ADDS';

/*news headlines*/
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const REMOVE_NEWS = 'REMOVE_NEWS';

/*website*/
export const FETCH_WEBSITE_SUCCESS = 'FETCH_WEBSITE_SUCCESS';
export const UPDATE_WEBSITE = 'UPDATE_WEBSITE';
export const REMOVE_WEBSITE = 'REMOVE_WEBSITE';

/*order by merchant id*/
export const FETCH_ORDERSBYMID_SUCCESS = 'FETCH_ORDERSBYMID_SUCCESS';
export const UPDATE_ORDERSBYMID = 'UPDATE_ORDERSBYMID';
export const REMOVE_ORDERSBYMID = 'REMOVE_ORDERSBYMID';

/*requirement*/
export const FETCH_REQUIREMENT_SUCCESS = 'FETCH_REQUIREMENT_SUCCESS';
export const UPDATE_REQUIREMENT = 'UPDATE_REQUIREMENT';
export const REMOVE_REQUIREMENT = 'REMOVE_REQUIREMENT';

/*merchant*/
export const FETCH_MERCHANT_SUCCESS = 'FETCH_MERCHANT_SUCCESS';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const REMOVE_MERCHANT = 'REMOVE_MERCHANT';

/*earlybirdreg*/
export const FETCH_EARLYBIRDREG_SUCCESS = 'FETCH_EARLYBIRDREG_SUCCESS';
export const UPDATE_EARLYBIRDREG = 'UPDATE_EARLYBIRDREG';
export const REMOVE_EARLYBIRDREG = 'REMOVE_EARLYBIRDREG';

/*orderTransaction*/
export const FETCH_ORDERTRANSACTION_SUCCESS = 'FETCH_ORDERTRANSACTION_SUCCESS';
export const UPDATE_ORDERTRANSACTION = 'UPDATE_ORDERTRANSACTION';
export const REMOVE_ORDERTRANSACTION = 'REMOVE_ORDERTRANSACTION';

/*orderCancleTransaction*/
export const FETCH_ORDERCANCLE_TRANSACTION_SUCCESS = 'FETCH_ORDERCANCLE_TRANSACTION_SUCCESS';
export const UPDATE_ORDERCANCLE_TRANSACTION = 'UPDATE_ORDERCANCLE_TRANSACTION';
export const REMOVE_ORDERCANCLE_TRANSACTION = 'REMOVE_ORDERCANCLE_TRANSACTION';

/*orderReturnTransaction*/
export const FETCH_ORDERRETURN_TRANSACTION_SUCCESS = 'FETCH_ORDERRETURN_TRANSACTION_SUCCESS';
export const UPDATE_ORDERRETURN_TRANSACTION = 'UPDATE_ORDERRETURN_TRANSACTION';
export const REMOVE_ORDERRETURN_TRANSACTION = 'REMOVE_ORDERRETURN_TRANSACTION';

/*merchantTransaction*/
export const FETCH_MERCHANT_TRANSACTION_SUCCESS = 'FETCH_MERCHANT_TRANSACTION_SUCCESS';
export const UPDATE_MERCHANT_TRANSACTION = 'UPDATE_MERCHANT_TRANSACTION';
export const REMOVE_MERCHANT_TRANSACTION = 'REMOVE_MERCHANT_TRANSACTION';

/*merchant payment*/
export const FETCH_MERCHANT_PAYMENT_SUCCESS = 'FETCH_MERCHANT_PAYMENT_SUCCESS';
export const UPDATE_MERCHANT_PAYMENT = 'UPDATE_MERCHANT_PAYMENT';
export const REMOVE_MERCHANT_PAYMENT = 'REMOVE_MERCHANT_PAYMENT';

/*cart by merchant id*/
export const FETCH_CARTSBYMID_SUCCESS = 'FETCH_CARTSBYMID_SUCCESS';
export const UPDATE_CARTSBYMID = 'UPDATE_CARTSBYMID';
export const REMOVE_CARTSBYMID = 'REMOVE_CARTSBYMID';

/*subscription*/
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';

/*productBYMID*/
export const FETCH_PRODUCTBYMID_SUCCESS = 'FETCH_PRODUCTBYMID_SUCCESS';
export const UPDATE_PRODUCTBYMID = 'UPDATE_PRODUCTBYMID';
export const REMOVE_PRODUCTBYMID = 'REMOVE_PRODUCTBYMID';
export const REMOVE_ALLPRODUCTBYMID = 'REMOVE_ALLPRODUCTBYMID';

/*banner*/
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const REMOVE_BANNER = 'REMOVE_BANNER';