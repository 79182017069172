import {
    FETCH_PRODUCTTYPE_SUCCESS,
    UPDATE_PRODUCTTYPE,
    REMOVE_PRODUCTTYPE,
} from '../contants';

const INIT_STATE = {
    productTypeData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_PRODUCTTYPE_SUCCESS:
            return {
                ...state,
                productTypeData: [action.payload, ...state.productTypeData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_PRODUCTTYPE:
            return {
                ...state,
                productTypeData: state.productTypeData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_PRODUCTTYPE:
            return {
                ...state,
                productTypeData: state.productTypeData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
