import {
    FETCH_VARIANT_SUCCESS,
    UPDATE_VARIANT,
    REMOVE_VARIANT,
} from '../contants';

const INIT_STATE = {
    variantData: [],
    isRefresh: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_VARIANT_SUCCESS:
            return {
                ...state,
                variantData: [action.payload, ...state.variantData],
                isRefresh: state.isRefresh,
            };
        case UPDATE_VARIANT:
            return {
                ...state,
                variantData: state.variantData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        case REMOVE_VARIANT:
            return {
                ...state,
                variantData: state.variantData.filter(item =>
                    !(item.currentPage === action.payload.currentPage && item.selectedPageSize === action.payload.selectedPageSize)
                ),
                isRefresh: !state.isRefresh,
            };
        default:
            return { ...state };
    }
};
